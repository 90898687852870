import styled from 'styled-components';
import BaseModal from '../BaseModal';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { SECONDARY_COLOR } from '../../constants';

interface I {
  show: boolean;
  setShow: (show: boolean) => void;
}

const ChangeAppearanceModal = ({ show, setShow }: I) => {
  const { chosenImage, setChosenImage, availableChatImages } =
    useGeneralContext();
  const handleClose = () => {
    setShow(false);
  };

  const handleChoose = (image: string) => {
    setChosenImage(image);
    setShow(false);
  };

  return (
    <Modal showModal={show} onRequestClose={handleClose}>
      <Text>Choose appearance</Text>
      <ImagesGrid>
        {availableChatImages.map((image, index) => (
          <ImageContainer
            key={index}
            onClick={() => handleChoose(image)}
            chosen={chosenImage === image}
          >
            <Image src={image} />
          </ImageContainer>
        ))}
      </ImagesGrid>
    </Modal>
  );
};

const Modal = styled(BaseModal)`
  display: flex;
  z-index: 3;
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 700;
  padding: 8px;
  padding-left: 10px;
`;

const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 2px;
  padding: 8px;
`;

const ImageContainer = styled.div<{ chosen: boolean }>`
  border: ${({ chosen }) =>
    chosen ? `2px solid ${SECONDARY_COLOR}` : '2px solid transparent'};
  padding: 2px;
  cursor: pointer;
  transition: border 0.3s;

  &:hover {
    border: 2px solid ${SECONDARY_COLOR};
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

export default ChangeAppearanceModal;
