import React, { useEffect } from 'react';
import styled from 'styled-components';
import BaseModal from '../BaseModal';
import SubscriptionCard from './SubscriptionCard';
import { desktopMediaQuery } from '../../styleHelpers';

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const SubscriptionModal: React.FC<I> = ({ showModal, setShowModal }) => {
  const closeModal = () => setShowModal(false);

  useEffect(() => {
    if (showModal) {
      alert(
        'The site is still free, this modal is just a placeholder for future subscription services',
      );
    }
  }, [showModal]);

  return (
    <Modal showModal={showModal} onRequestClose={closeModal} showWiderVersion>
      <ModalContent>
        <SubscriptionCard variation="free" />
        <SubscriptionCard variation="silver" />
        <SubscriptionCard variation="gold" />
        <SubscriptionCard variation="platinum" />
      </ModalContent>
    </Modal>
  );
};

const Modal = styled(BaseModal)``;

const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${desktopMediaQuery} {
    flex-direction: column;
    align-items: center;
  }
  padding: 16px;
  padding-top: 24px;
  font-size: 22px;
  overflow-y: auto;

  max-height: 88vh;
  width: 99%;
  max-width: 80vw;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export default SubscriptionModal;
