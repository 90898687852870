import React, { useState, useEffect, ReactChild } from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import { desktopMediaQuery } from '../styleHelpers';

interface ReusableCarouselProps {
  children?: ReactChild[];
  onChangeItem?: (index: number) => void;
  initialHeight?: number;
  selectedItem: number;
  setSelectedItem: (index: number) => void;
}

const ReusableCarousel = ({
  children,
  onChangeItem,
  initialHeight = 512,
  selectedItem,
  setSelectedItem,
}: ReusableCarouselProps) => {
  const [carouselHeight, setCarouselHeight] = useState(initialHeight);

  const minHeight = 512;
  const heightToUse = carouselHeight < minHeight ? minHeight : carouselHeight;

  useEffect(() => {
    const updateHeight = () => {
      const activeSlide = document.querySelector('.carousel .slide.selected');
      if (activeSlide) {
        setCarouselHeight(activeSlide.clientHeight);
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [selectedItem]);

  const handleChange = (index: number) => {
    setSelectedItem(index);
    if (onChangeItem) {
      onChangeItem(index);
    }
  };

  return (
    <CarouselContainer>
      <StyledCarousel
        height={heightToUse}
        showIndicators={children && children.length < 7}
        showThumbs={false}
        showStatus={false}
        selectedItem={selectedItem}
        emulateTouch
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={80}
        onChange={handleChange}
        useKeyboardArrows={true}
      >
        {children}
      </StyledCarousel>
    </CarouselContainer>
  );
};

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98vw;
  margin-bottom: 4px;
  min-height: 512px;

  ${desktopMediaQuery} {
    width: 50vw;
    max-width: 768px;
  }
`;

const StyledCarousel = styled(Carousel)<{ height: number }>`
  .carousel-slider {
    height: ${(props) => props.height}px;
    transition: height 0.3s ease-in-out;
  }
`;

export default ReusableCarousel;
