import React from 'react';
import { MdOutlineLogout } from 'react-icons/md';
import { toast } from 'react-toastify';

import UserTokenStorage from '../../../../common/storages/userTokenStorage';
import { OptionContainer, ButtonWithIcon } from './TopMenuCommon';
import { useAuthContext } from '../../../../context/AuthContextProvider';
import styled from 'styled-components';
import { FaUserPlus, FaSignInAlt } from 'react-icons/fa';

interface P {
  closePopup: () => void;
}

export const LogoutOption: React.FC<P> = ({ closePopup }) => {
  const userTokenStorage = new UserTokenStorage();
  const { setLoggedInUserToken } = useAuthContext();
  const onLogout = () => {
    setLoggedInUserToken('');
    userTokenStorage.removeToken();
    toast.success('Logged out successfully');
    closePopup();
  };
  return (
    <OptionContainer onClick={onLogout}>
      <ButtonWithIcon>
        <MdOutlineLogout size={18} />
        Logout
      </ButtonWithIcon>
    </OptionContainer>
  );
};

export const LoginAndSignupOptions: React.FC<P> = ({ closePopup }) => {
  const { setAuthModalActiveVariant, setShowAuthModal } = useAuthContext();
  const handleClickLogin = () => {
    closePopup();
    setAuthModalActiveVariant('login');
    setShowAuthModal(true);
  };

  const handleClickSignup = () => {
    closePopup();
    setAuthModalActiveVariant('signup');
    setShowAuthModal(true);
  };
  return (
    <OptionsContainer>
      <OptionContainer onClick={handleClickSignup}>
        <ButtonWithIcon>
          <FaUserPlus />
          Sign Up
        </ButtonWithIcon>
      </OptionContainer>
      <OptionContainer onClick={handleClickLogin}>
        <LoginContainer>
          <FaSignInAlt />
          Login
        </LoginContainer>
      </OptionContainer>
    </OptionsContainer>
  );
};

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LoginContainer = styled(ButtonWithIcon)`
  margin-left: -2px;
  gap: 10px;
`;

export default {};
