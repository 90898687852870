class ImageStorage {
  private storageKey: string;

  constructor() {
    this.storageKey = 'userImages';
  }

  getImages(): any[] {
    const images = localStorage.getItem(this.storageKey);
    return images ? JSON.parse(images) : [];
  }

  addImage(image: any): void {
    const images = this.getImages();
    images.unshift(image);
    localStorage.setItem(this.storageKey, JSON.stringify(images));
  }
}

export default ImageStorage;
