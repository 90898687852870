/**
 * Cleans up the user-provided name by performing the following operations:
 * 1. Trims leading and trailing whitespace.
 * 2. Removes common prefixes such as "I'm", "I am", "My name is", etc.
 * 3. Removes punctuation.
 * 4. Normalizes multiple spaces to a single space.
 * 5. Capitalizes the first letter of each name part.
 *
 * @param userName - The raw input string provided by the user.
 * @returns A sanitized and properly formatted user name.
 *
 * @example
 * cleanUpUserName("   I'm John Doe   "); // Returns "John Doe"
 * cleanUpUserName("  i am jane   "); // Returns "Jane"
 * cleanUpUserName("My name is   Alice   Wonderland "); // Returns "Alice Wonderland"
 * cleanUpUserName("call me bob."); // Returns "Bob"
 * cleanUpUserName(" this is charlie! "); // Returns "Charlie"
 */
export const cleanUpUserName = (userName: string): string => {
    if (!userName) return '';
  
    // Step 1: Trim leading and trailing whitespace
    let cleanedName = userName.trim();
  
    // Step 2: Remove common prefixes using regex (e.g., "I'm", "I am", "My name is")
    const prefixes = [
      /^i'm\s+/i,
      /^im\s+/i,
      /^i am\s+/i,
      /^my name is\s+/i,
      /^call me\s+/i,
      /^this is\s+/i,
    ];
    
    prefixes.forEach(prefix => {
      cleanedName = cleanedName.replace(prefix, '');
    });
  
    // Step 3: Remove any remaining punctuation
    cleanedName = cleanedName.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()'"?<>]/g, '');
  
    // Step 4: Replace multiple spaces with a single space
    cleanedName = cleanedName.replace(/\s+/g, ' ');
  
    // Step 5: Capitalize the first letter of each name part
    cleanedName = cleanedName
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  
    return cleanedName;
  };
  