import styled from 'styled-components';
import { PRIMARY_COLOR, BACKGROUND_COLOR } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';

const PrimaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: 1px solid ${PRIMARY_COLOR};
  background-color: ${BACKGROUND_COLOR};
  border-radius: 8px;
  padding: 8px;

  cursor: pointer;
  transition: transform 0.3s ease;
  ${desktopMediaQuery} {
    &:hover {
      transform: translateY(-2px);
    }
  }
`;

export default PrimaryButton;
