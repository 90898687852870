import styled from 'styled-components';
import { PRIMARY_TEXT_COLOR, BACKGROUND_COLOR } from '../constants';

const SingleLineInputField = styled.input`
  border: none;
  width: 100%;
  border-radius: 24px;
  padding: 12px;
  padding-bottom: 10px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  color: ${PRIMARY_TEXT_COLOR};
  background-color: transparent;
  word-break: break-word;
  &:focus {
    outline: none;
  }
  :-webkit-autofill {
    filter: none;
    box-shadow: 0 0 0 40px ${BACKGROUND_COLOR} inset;
    -webkit-text-fill-color: ${PRIMARY_TEXT_COLOR} !important;
  }
`;

export default SingleLineInputField;
