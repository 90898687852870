import { FaDiscord, FaReddit } from 'react-icons/fa';
import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../constants';
interface I {
  bigger?: boolean;
}

const DISCORD_URL = 'https://discord.gg/3fkkpNbbxh';
export const DiscordLogo = ({ bigger }: I) => {
  const handleTakeToDiscord = () => {
    window.open(DISCORD_URL, '_blank');
  };

  if (bigger)
    return (
      <BiggerContainer onClick={handleTakeToDiscord}>
        Join Discord <DiscordIcon />
      </BiggerContainer>
    );
  return (
    <SmallContainer onClick={handleTakeToDiscord}>
      <DiscordIcon onClick={handleTakeToDiscord} size={18} /> Join Discord
    </SmallContainer>
  );
};

const sharedIconStyle = `
  font-size: 24px;
  color: #fff;
`;

const DiscordIcon = styled(FaDiscord)`
  ${sharedIconStyle}
`;

const BiggerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 28px;

  &:hover {
    cursor: pointer;
    color: ${PRIMARY_COLOR};
  }
`;

const SmallContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const REDDIT_URL = 'https://www.reddit.com/r/joi_dot_bot';

export const RedditLogo = ({ bigger }: I) => {
  const handleTakeToReddit = () => {
    window.open(REDDIT_URL, '_blank');
  };

  if (bigger)
    return (
      <BiggerContainer onClick={handleTakeToReddit}>
        Visit subreddit <RedditIcon />
      </BiggerContainer>
    );
  return (
    <SmallContainer onClick={handleTakeToReddit}>
      <RedditIcon size={18} />
      Visit subreddit
    </SmallContainer>
  );
};

const RedditIcon = styled(FaReddit)`
  ${sharedIconStyle}
`;

/*
export const TwitterLogo = () => {
  const handleTakeToTwitter = () => {
    window.open('https://twitter.com/onlyfakes_app', '_blank');
  };

  return <TwitterIcon onClick={handleTakeToTwitter} />;
};

const TwitterIcon = styled(FaTwitter)`
  margin-left: 8px;
  ${sharedIconStyle}
`;
*/
