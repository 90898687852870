import { useEffect } from 'react';
import { getIsJoiBot } from '../../helpers';

const useHandleHtmlManipulationForSEO = (): void => {
  useEffect(() => {
    // Function to set or update a meta tag
    const setMetaTag = (name: string, content: string): void => {
      let meta = document.querySelector(`meta[name="${name}"]`);
      if (!meta) {
        meta = document.createElement('meta');
        meta.setAttribute('name', name);
        document.head.appendChild(meta);
      }
      meta.setAttribute('content', content);
    };

    // Function to set the document title
    const setTitle = (title: string): void => {
      document.title = title;
    };

    // Function to add a script to the head
    const addScript = (
      src: string,
      attrs: Record<string, string> = {}
    ): HTMLScriptElement => {
      const script = document.createElement('script');
      script.src = src;
      Object.keys(attrs).forEach((key) => {
        script.setAttribute(key, attrs[key]);
      });
      script.defer = true;
      document.head.appendChild(script);
      return script;
    };

    const isJoiBot = getIsJoiBot()

    // Configuration based on the domain
    const config: {
      description: string;
      keywords: string;
      title: string;
      scriptDataDomain: string;
      scriptSrc: string;
    } = {
      description: isJoiBot
        ? 'Uncensored and unfiltered AI conversations. Have hot chats, such as jerk off instructions'
        : 'Uncensored AI chat. Explore AI Sexting and your wildest fetishes',
      keywords: isJoiBot
        ? 'joi bot, unsencored chatbot, jerk off instruction bot'
        : 'sexting ai, sexting bot, ai jerk off instruction, best ai sex bot',
      title: isJoiBot
        ? 'Joi.bot - Your Uncensored AI Companion'
        : 'The Free AI Sexting Experience',
      scriptDataDomain: isJoiBot ? 'joi.bot' : 'sextingai.net',
      scriptSrc: 'https://plausible.io/js/script.tagged-events.js',
    };

    // Set meta tags and title using the configuration
    setMetaTag('description', config.description);
    setMetaTag('keywords', config.keywords);
    setTitle(config.title);

    // Add the Plausible script with the appropriate data-domain
    const scriptElement: HTMLScriptElement = addScript(config.scriptSrc, {
      'data-domain': config.scriptDataDomain,
    });

    // Cleanup function to remove the script when component unmounts
    return () => {
      if (scriptElement) {
        document.head.removeChild(scriptElement);
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount
};

export default useHandleHtmlManipulationForSEO;
