import React from 'react';
import styled from "styled-components";
import { CardContainer, CardTitle, CardText } from "../../common/components/cardParts";
import { desktopMediaQuery, getIsOnDesktop } from "../../styleHelpers";
import ImagesInLanding from "./ImagesInLanding";

interface JoiBotDescriptionProps {
  title: string;
  description: string;
  images?: string[];
  alternateOrder?: boolean;
}

const LandingPageCard: React.FC<JoiBotDescriptionProps> = ({
  title,
  description,
  images,
  alternateOrder,
}) => {
  const isDesktop = getIsOnDesktop();
  if (alternateOrder && isDesktop) {
    return (
      <ResponsiveContainer>
        <ImagesInLanding images={images} />
        <CardContainer>
          <CardTitle>{title}</CardTitle>
          <CardText>{description}</CardText>
        </CardContainer>
      </ResponsiveContainer>
    );
  }
  return (
    <ResponsiveContainer>
      <CardContainer>
        <CardTitle>{title}</CardTitle>
        <CardText>{description}</CardText>
      </CardContainer>
      <ImagesInLanding images={images} />
    </ResponsiveContainer>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 650px;

  ${desktopMediaQuery} {
    flex-direction: row;
    justify-content: center;
    gap: 5vw;
    max-width: 60vw;
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

export default LandingPageCard;
