import { useEffect } from 'react';

const NoIndexMetaTag: React.FC = () => {
  useEffect(() => {
    const metaTag = document.createElement('meta');
    metaTag.name = 'robots';
    metaTag.content = 'noindex';
    document.head.appendChild(metaTag);

    return () => {
      document.head.removeChild(metaTag); // Clean up when component unmounts
    };
  }, []);

  return null;
};

export default NoIndexMetaTag;
