import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { desktopMediaQuery } from '../../styleHelpers';
import { waitSeconds } from '../../helpers';

const AudioPlayer = ({ src }: { src: string }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const togglePlayPause = async () => {
    setIsPlaying(!isPlaying);
    if (!audioRef.current) {
      await waitSeconds(0.1);
    }
    if (isPlaying) {
      audioRef.current && audioRef.current.pause();
    } else {
      audioRef.current && audioRef.current.play();
    }
  };

  const handleClick = () => {
    if (!isLoaded) {
      setIsLoaded(true);
    }
    togglePlayPause();
  };

  return (
    <Container onClick={handleClick} style={{ cursor: 'pointer' }}>
      {isLoaded && <audio ref={audioRef} src={src} loop />}
      {!isPlaying ? '🔇' : '🔊'}
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  z-index: 1000;
  border-radius: 45%;
  padding: 8px;
  font-size: 20px;

  bottom: 25px;
  right: 16px;

  background-color: rgba(255, 255, 255, 0.05);

  ${desktopMediaQuery} {
    font-size: 36px;
    bottom: 24px;
    right: 24px;
  }
`;

export default AudioPlayer;
