import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { desktopMediaQuery } from '../../styleHelpers';
import { fadeInWithBounce } from '../../common/animations';
import { DEFAULT_IMAGES } from '../../constants';

interface ImagesInLandingProps {
  images?: string[];
}

const PLACEHOLDER_COMPRESSED_IMAGE = '/compressedLandingPageImage1.png';
const ImagesInLanding = ({ images }: ImagesInLandingProps) => {
  const editImageExamples = images?.length ? images : DEFAULT_IMAGES;
  const currentIndex = useUpdateImages(editImageExamples, 3000, 3000);

  const [imageSrc, setImageSrc] = useState(PLACEHOLDER_COMPRESSED_IMAGE);

  useEffect(() => {
    const img = new Image();
    img.src = editImageExamples[currentIndex];
    img.onload = () => setImageSrc(editImageExamples[currentIndex]);
    img.onerror = () => setImageSrc(PLACEHOLDER_COMPRESSED_IMAGE);
  }, [currentIndex, editImageExamples]);

  return (
    <Container key={currentIndex}>
      <StyledImg src={imageSrc} key={currentIndex} alt="Example image" />
    </Container>
  );
};

const useUpdateImages = (
  exampleImages: any[],
  timePerImage: number,
  firstImageTime: number,
) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageRefs = useRef(exampleImages.map(() => new Image()));

  useEffect(() => {
    imageRefs.current.forEach((img, index) => {
      img.src = exampleImages[index];
    });
  }, [exampleImages]);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % exampleImages.length);
      },
      currentIndex === 0 ? firstImageTime : timePerImage,
    );

    return () => clearTimeout(timer);
  }, [currentIndex, exampleImages, timePerImage, firstImageTime]);

  return currentIndex;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

const StyledImg = styled.img`
  animation: ${fadeInWithBounce} 0.3s ease-in;
  height: 300px;
  object-fit: contain;
  border-radius: 16px;

  ${desktopMediaQuery} {
    height: 450px;
  }

  margin-bottom: 16px;
`;

export default ImagesInLanding;
