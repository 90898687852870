import { getIsJoiBot } from "./helpers";

export const PRIMARY_COLOR = '#a30502';
export const ORANGE_LINK_COLOR = '#f78b04';
export const SECONDARY_COLOR = ORANGE_LINK_COLOR;
export const DARKER_PRIMARY_COLOR = '#642f70';
export const GOLD_COLOR = '#FFD700';
export const HOVER_COLOR = DARKER_PRIMARY_COLOR;
export const BACKGROUND_COLOR = '#131313';

export const TOAST_CARD_BACKGROUND_COLOR = '#2a2a2a';
export const CARD_BACKGROUND_COLOR = TOAST_CARD_BACKGROUND_COLOR;

export const LIGHTER_BG_FOR_TOAST = TOAST_CARD_BACKGROUND_COLOR;

export const USER_MESSAGE_COLOR = '#303134'; //'#2a1f3d';
export const BOT_MESSAGE_COLOR = PRIMARY_COLOR;

export const GREEN_COLOR = '#00FF00';

export const PRIMARY_TEXT_COLOR = '#F0F0F0';
export const ICON_COLOR = PRIMARY_TEXT_COLOR;

export const SECONDARY_TEXT_COLOR = '#a8a8a8';
export const BORDER_COLOR = SECONDARY_TEXT_COLOR;

export const LIGHTER_GREY_COLOR = '#a3a3a3';

export const EVEN_LIGHTER_GREY_COLOR = '#e3e3e3';

export const LIGHT_GREY_COLOR = '#303134';
export const LIGHTER_GREY_FOR_HOVER_EFFECT = '#1f1a2b';

export const DARK_GREY_COLOR = '#1f1a2b';

export const DARKER_GOLD_COLOR = '#FFC400';

export const previewBaseBaseUrl = 'https://deploy-preview-1--joi.netlify.app';

export const PREVIEW_URL_FOR_FUNCTIONS = `${previewBaseBaseUrl}/.netlify/functions`;

export const BASE_URL = 'https://sextingAI.net';

export const BASE_URL_SAI = 'https://sextingai.net';

export const DESKTOP_WIDTH = 768;

export const MS_IN_SECOND = 1000;

export const MAX_WIDTH = 768;

export const LOCAL_STORAGE_ACCEPTED_TERMS_KEY = 'acceptedTerms';

export const GIRLFRIEND_PERSONA =
  'Girlfriend, cute, loving, caring, sexy, flirty';
export const HORNY_PERSONA =
  'She is Horny, explicit, a girlfriend, always ready to fuck or sexting. Very flirty';
export const JERK_OFF_INSTRUCTIONS =
  "When asked for Jerk-off Instructions, they provide step by step instructions, slowly building up tension. Show don't tell";
export const DEFAULT_BOT_PERSONA = 'Flirty, friendly, fun';

export const DEFAULT_BOT_NAME = getIsJoiBot() ? 'Joi' : 'Sophia';
export const DEFAULT_USER_NAME = 'Joe'; // not used atm

export const DEFAULT_GIFS = [
  'example5.webp', //'example5.png',
  'example2.webp', //'example2.png',
  'example3.webp', //'example3.png',
  'example4.webp', //'example4.png',
  'example1.webp', //'example1.png',
  'example6.webp', //'example6.png',
];

export const DEFAULT_IMAGES = [
  'example5.png',
  'example2.png',
  'example3.png',
  'example4.png',
  'example1.png',
  'example6.png',
];

export const DEFAULT_IMAGES_OLD = [
  'example1.jpeg',
  'example2.jpeg',
  'example3.jpeg',
  'example4.jpeg',
  'example5.jpeg',
  'example6.jpeg',
];

export const DEFAULT_IMAGE_TO_USE = DEFAULT_IMAGES[0];

export const ONBOARDING_TYPE_MSG = 'onboarding';
export const HIDDEN_TYPE_MSG = 'hidden';

export const assistantRole = 'assistant';
export const userRole = 'user';
export const systemRole = 'system';
