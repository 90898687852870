import { useRef, useState } from 'react';
import { FaEllipsisV, FaExclamationCircle, FaImage } from 'react-icons/fa';
import styled from 'styled-components';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  PRIMARY_TEXT_COLOR,
  SECONDARY_COLOR,
} from '../../constants';
import { fadeInWithBounce } from '../../common/animations';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import useRequestImage from '../../hooks/useRequestImage';
import useHandleClickOutside from '../MainApp/MainAppCommon/TopBar/useHandleClickOutside';

interface ExtraOptionsIconAndMenuProps {
  handleFocusOnInput: () => void;
}

const ExtraOptionsIconAndMenu = ({ handleFocusOnInput }: ExtraOptionsIconAndMenuProps) => {
  const { isDoingAction, setIsDoingAction } = useGeneralContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const requestImage = useRequestImage();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const popupRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef(null);
  // event listener to close popup when clicking outside of it
  useHandleClickOutside([popupRef, buttonRef], toggleMenu);

  const handleDoAction = () => {
    setIsDoingAction((prev) => !prev);
    toggleMenu();
    handleFocusOnInput();
  };

  const SHOW_ASK_IMAGE = false;

  const handleAskForImage = () => {
    //requestImage();
    alert('Not implemented yet');
    toggleMenu();
  };

  return (
    <ExtraOptionsIconAndMenuContainer>
      <div ref={buttonRef} onClick={toggleMenu}>
        <ExtraOptionsIcon />
      </div>
      {isMenuOpen && (
        <ExtraOptionsMenu ref={popupRef}>
          <Option onClick={handleDoAction} isSelected={isDoingAction}>
            <FaExclamationCircle />
            Do action
          </Option>
          {SHOW_ASK_IMAGE && (
            <Option onClick={handleAskForImage}>
              <FaImage />
              Ask for image
            </Option>
          )}
        </ExtraOptionsMenu>
      )}
    </ExtraOptionsIconAndMenuContainer>
  );
};

export default ExtraOptionsIconAndMenu;

const ExtraOptionsIconAndMenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const ExtraOptionsIcon = styled(FaEllipsisV)`
  padding-left: 12px;
  padding-top: 4px;
`;

const ExtraOptionsMenu = styled.div`
  position: absolute;
  left: 0;
  bottom: 24px;
  background-color: ${BACKGROUND_COLOR};
  border: 1px solid ${BORDER_COLOR};
  border-radius: 8px;
  padding: 8px;
  width: 200px;

  animation: ${fadeInWithBounce} 0.1s ease-in-out;
`;

const Option = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  padding: 8px;
  color: ${({ isSelected }) =>
    isSelected ? SECONDARY_COLOR : PRIMARY_TEXT_COLOR};
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};

  transition: background-color 0.2s;
  cursor: pointer;
  &:hover {
    background-color: ${BORDER_COLOR};
  }
`;
