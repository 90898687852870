import { createContext, useContext, useState } from 'react';
import { ChatMessage, GenericSetState } from '../types';
import {
  DEFAULT_BOT_NAME,
  DEFAULT_BOT_PERSONA,
  DEFAULT_IMAGES,
} from '../constants';
import UserSettingsStorage from '../common/storages/userSettingsStorage';

interface IGeneralContext {
  botName: string;
  setBotName: (name: string) => void;

  userName: string;
  setUserName: (name: string) => void;

  botPersona: string;
  setBotPersona: (instructions: string) => void;

  availableChatImages: string[];
  setAvailableChatImages: GenericSetState<string[]>;

  chosenImage: string;
  setChosenImage: (imageUrl: string) => void;

  messages: ChatMessage[];
  setMessages: GenericSetState<ChatMessage[]>;

  botIsTyping: boolean;
  setBotIsTyping: GenericSetState<boolean>;

  // HELPER
  isFocusedOnKeyboard: boolean;
  setIsFocusedOnKeyboard: GenericSetState<boolean>;

 // helper for chatinput 
  isDoingAction: boolean;
  setIsDoingAction: GenericSetState<boolean>;
}

const defaultState = {
  botName: DEFAULT_BOT_NAME,
  setBotName: undefined as unknown as GenericSetState<string>,

  userName: '',
  setUserName: undefined as unknown as GenericSetState<string>,

  botPersona: DEFAULT_BOT_PERSONA,
  setBotPersona: undefined as unknown as GenericSetState<string>,

  availableChatImages: DEFAULT_IMAGES,
  setAvailableChatImages: undefined as unknown as GenericSetState<string[]>,

  chosenImage: "",
  setChosenImage: undefined as unknown as GenericSetState<string>,

  messages: [],
  setMessages: undefined as unknown as GenericSetState<ChatMessage[]>,

  botIsTyping: false,
  setBotIsTyping: undefined as unknown as GenericSetState<boolean>,

  // HELPER
  isFocusedOnKeyboard: false,
  setIsFocusedOnKeyboard: undefined as unknown as GenericSetState<boolean>,

  isDoingAction: false,
  setIsDoingAction: undefined as unknown as GenericSetState<boolean>,
};

const GeneralContext = createContext<IGeneralContext>(defaultState);

export const useGeneralContext = () => useContext(GeneralContext);

// @ts-ignore children does actually exist, todo figure types?
const GeneralContextProvider = ({ children }) => {
  const userSettingsStorage = new UserSettingsStorage();

  const [botName, setBotNameState] = useState<string>(DEFAULT_BOT_NAME);
  const setBotName = (name: string) => {
    setBotNameState(name);
    userSettingsStorage.setBotName(name);
  }
  const [botPersona, setBotPersonaState] = useState<string>(
    DEFAULT_BOT_PERSONA,
  );
  const setBotPersona = (instructions: string) => {
    setBotPersonaState(instructions);
    userSettingsStorage.setBotPersona(instructions);
  }

  const [userName, setUserNameState] = useState<string>('');
  const setUserName = (name: string) => {
    setUserNameState(name);
    userSettingsStorage.setUserName(name);
  };

  const [availableChatImages, setAvailableChatImages] =
    useState<string[]>(DEFAULT_IMAGES);

  const [chosenImage, setChosenImageState] = useState<string>('');
  const setChosenImage = (imageUrl: string) => {
    setChosenImageState(imageUrl);
    userSettingsStorage.setChosenImage(imageUrl);
  }

  // will be replaced with somethign else
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  const [botIsTyping, setBotIsTyping] = useState(false);

  const [isFocusedOnKeyboard, setIsFocusedOnKeyboard] = useState(false);

  const [isDoingAction, setIsDoingAction] = useState(false);

  return (
    <GeneralContext.Provider
      // TODO: Figure out if needed to wrap in useMemo
      // https://stackoverflow.com/questions/62230532/is-usememo-required-to-manage-state-via-the-context-api-in-reactjs
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        botName,
        setBotName,
        botPersona,
        setBotPersona,

        userName,
        setUserName,

        availableChatImages: availableChatImages,
        setAvailableChatImages: setAvailableChatImages,

        chosenImage,
        setChosenImage,

        messages,
        setMessages,

        botIsTyping,
        setBotIsTyping,

        isFocusedOnKeyboard,
        setIsFocusedOnKeyboard,

        isDoingAction,
        setIsDoingAction,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

export default GeneralContextProvider;
