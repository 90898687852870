import ReactModal from 'react-modal';
import styled, { keyframes } from 'styled-components';
import { BACKGROUND_COLOR, BORDER_COLOR } from '../constants';
import { getIsOnDesktop } from '../styleHelpers';
import { MdClose } from 'react-icons/md';

interface I {
  showModal: boolean;
  onRequestClose: () => void;
  children: React.ReactNode;
  showWiderVersion?: boolean;
  transparent?: boolean;
}

const BaseModal = ({
  showModal,
  onRequestClose,
  children,
  showWiderVersion,
  transparent,
}: I) => {
  const isOnDesktop = getIsOnDesktop();
  return (
    <TransitioningModal
      transparent={transparent}
      isOpen={showModal}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          zIndex: 10,
        },
        content: {
          position: 'relative',
          backgroundColor: transparent ? 'none' : BACKGROUND_COLOR,
          padding: '16px',
          width: '85vw',
          maxWidth: isOnDesktop && showWiderVersion ? '900px' : '400px',
          minHeight: '300px',
          maxHeight: '89vh',
          overflowY: 'hidden',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={onRequestClose}
    >
      <CloseButton onClick={onRequestClose}>
        <MdClose />
      </CloseButton>
      {children}
    </TransitioningModal>
  );
};

const CloseButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;

  font-size: 24px;

  z-index: 5;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

/**
 * This is a modal that fades in and out when it opens and closes.
 */
const TransitioningModal = styled(ReactModal)<{
  isOpen: boolean;
  slower?: boolean;
  transparent?: boolean;
}>`
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  animation: ${(props) => (props.isOpen ? fadeIn : fadeOut)} 0.3s ease-in-out;
  // if slower, then 0.7s ease-in-out
  animation-duration: ${(props) => (props.slower ? '0.8s' : '0.3s')};
  border: ${(props) =>
    props.transparent ? 'none' : `1px solid ${BORDER_COLOR};`};
  border-radius: 8px;

  overflow-y: auto;
`;

export default BaseModal;
