import { useEffect, useState } from 'react';
import { getIsJoiBot, waitSeconds } from '../../helpers';
import styled from 'styled-components';
import MessageBubble from '../../common/components/messages/MessageBubble';
import { desktopMediaQuery } from '../../styleHelpers';
import { ChatMessage } from '../../types';

const exampleMessagesJoi = [
  {
    role: 'Joi',
    content: 'You look lonely',
  },
  {
    role: 'Joi',
    content: 'I can fix that 😘',
  },
];

const exampleMessagesSAI = [
  {
    role: 'Sophia (AI)',
    content: 'Hi there 😘 wanna chat?',
  },
  {
    role: 'Sophia (AI)',
    content: 'How about some jerk-off instructions? ',
  },
];
const ExampleMessage = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  useEffect(() => {
    //set first message after second, then wait second and set second
    const handleSettingMessagesJoi = async () => {
      await waitSeconds(0.5);
      setMessages([exampleMessagesJoi[0]]);
      await waitSeconds(1.5);
      setMessages([exampleMessagesJoi[0], exampleMessagesJoi[1]]);
    };

    const handleSettingMessagesSAI = async () => {
      await waitSeconds(0.5);
      setMessages([exampleMessagesSAI[0]]);
      await waitSeconds(1.5);
      setMessages([exampleMessagesSAI[0], exampleMessagesSAI[1]]);
    };
    const isJoi = getIsJoiBot();
    if (isJoi) {
      handleSettingMessagesJoi();
    } else {
      handleSettingMessagesSAI();
    }
  }, []);

  return (
    <Container>
      {messages.map((message, index) => (
        <MessageBubble key={index} message={message} />
      ))}
    </Container>
  );
};

export default ExampleMessage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${desktopMediaQuery} {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  gap: 0;

  width: 100%;
  min-height: 140px;
`;
