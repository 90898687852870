import { useRef, useEffect } from 'react';
import { ChatMessage } from '../../types';
import MessageBubble from '../../common/components/messages/MessageBubble';
import styled from 'styled-components';
import TypingAnimation from './TypingAnimation';
import { waitSeconds } from '../../helpers';

interface ChatMessagesProps {
  messages: ChatMessage[];
  botIsTyping?: boolean;
}

const ChatMessages = ({ messages, botIsTyping }: ChatMessagesProps) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = async (waitTime: number) => {
    await waitSeconds(waitTime);
    if (!messagesEndRef.current) {
      await waitSeconds(0.3)
    }
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom(1);
  }, []);

  useEffect(() => {
    scrollToBottom(0.3);
  }, [messages, botIsTyping]);

  const pushMessagesToEnd = messages.length > 1 && messages.length <= 3;
  const isLastMessage = (index: number) => index === messages.length - 1;
  return (
    <MessagesContainer pushMessagesToEnd={pushMessagesToEnd}>
      {messages.map((message, index) => (
        <MessageBubble
          isLastMessage={isLastMessage(index)}
          key={index}
          message={message}
        />
      ))}
      {botIsTyping && <TypingAnimation />}
      <div ref={messagesEndRef} />
    </MessagesContainer>
  );
};

const MessagesContainer = styled.div<{
  pushMessagesToEnd: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 8px;

  flex-grow: 1;
  overflow-y: auto;
  box-sizing: border-box;
  max-height: 70vh;

  ${({ pushMessagesToEnd }) =>
    pushMessagesToEnd && 'justify-content: flex-end;'}
`;

export default ChatMessages;
