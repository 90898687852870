import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { BASE_URL, BASE_URL_SAI, PREVIEW_URL_FOR_FUNCTIONS } from './constants';
import GeneralContextProvider from './context/GeneralContextProvider';
import useHandleGatherReferralId from './hooks/useHandleGatherReferralId';
import { ModalsContextProvider } from './context/ModalsContextProvider';
import { AuthContextProvider } from './context/AuthContextProvider';
import useHandleHtmlManipulationForSEO from './hooks/misc/useHandleHtmlManipulationForSEO';
import { getIsJoiBot } from './helpers';

export const isLocalhost = window.location.href.includes('localhost');
export const isPreviewing = window.location.href.includes('deploy-preview');
export const isInNetlify = window.location.href.includes('netlify.app');

export const getBaseUrlForFetching = () => {
  const isJoiBot = getIsJoiBot();
  if (isLocalhost) {
    return 'http://localhost:8080';
  }
  if (isPreviewing) {
    return PREVIEW_URL_FOR_FUNCTIONS;
  }
  if (isInNetlify) {
    return 'https://joibot.netlify.app/.netlify/functions';
  }
  if (isJoiBot) {
    return `${BASE_URL}/.netlify/functions`;
  }
  return `${BASE_URL_SAI}/.netlify/functions`;
};

/**
 * This is called from index.tsx and is the main entry point of the app
 */
function App() {
  useHandleGatherReferralId();

  useHandleHtmlManipulationForSEO();
  return (
    <Router>
      <AuthContextProvider>
        <GeneralContextProvider>
          <ModalsContextProvider>
            <Routes />
          </ModalsContextProvider>
        </GeneralContextProvider>
      </AuthContextProvider>
    </Router>
  );
}

export default App;
