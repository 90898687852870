/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';
import { useState } from 'react';
import ReusableCarousel from '../../common/ReusableCarousel';
import BaseModal from '../BaseModal';
import { useGeneralContext } from '../../context/GeneralContextProvider';

interface I {
  show: boolean;
  setShow: (show: boolean) => void;
}

const ShowImagesModal = ({ show, setShow }: I) => {
  const { chosenImage } = useGeneralContext();
  const handleClose = () => {
    setShow(false);
  };

  const [selectedItem, setSelectedItem] = useState(0);

  const handleChange = (index: number) => {
    setSelectedItem(index);
  };

  return (
    <Modal showModal={show} onRequestClose={handleClose} transparent>
      <ImageContainer>
        <Image src={chosenImage} alt="image" />
      </ImageContainer>
    </Modal>
  );
};

const Modal = styled(BaseModal)`
  display: flex;
  z-index: 3;
`;

const StyledCarousel = styled(ReusableCarousel)`
  display: flex;
`;

const maxHeight = 89;
const maxForImage = maxHeight - 7;

const maxWidth = 95;
const maxForImageWidth = maxWidth - 7;
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  max-height: ${maxHeight}vh;
  max-width: ${maxWidth}vw;
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  max-height: ${maxForImage}vh;
  max-width: ${maxForImageWidth}vw;
  border-radius: 8px;
`;

export default ShowImagesModal;
