/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';
import { useRef } from 'react';
import { FaQuestion } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { BORDER_COLOR, BACKGROUND_COLOR } from '../../../../constants';
import useHandleClickOutside from './useHandleClickOutside';

import { LoginAndSignupOptions, LogoutOption } from './LoginAndLogoutOptions';
import { CreditDiamondIcon } from '../../../../common/components/CreditDiamondIcon';
import { OptionContainer, ButtonWithIcon } from './TopMenuCommon';
import {
  DiscordLogo,
  RedditLogo,
} from '../../../../common/components/SocialLogos';
import { useAuthContext } from '../../../../context/AuthContextProvider';
import { useModalsContext } from '../../../../context/ModalsContextProvider';

interface P {
  buttonRef: React.RefObject<HTMLButtonElement>;
  popupX: number;
  showPopup: boolean;
  closePopup: () => void;
}

/*
      <OptionContainer onClick={handleBuyCredits}>
        <ButtonWithIcon>
          <CreditDiamondIcon />
          Buy Credits
        </ButtonWithIcon>
      </OptionContainer>
*/

const TopBarMenu = ({ buttonRef, popupX, showPopup, closePopup }: P) => {
  const { loggedInUserToken } = useAuthContext();
  const { setShowBuySubscriptionModal } = useModalsContext();
  const popupRef = useRef(null);

  // event listener to close popup when clicking outside of it
  useHandleClickOutside([popupRef, buttonRef], closePopup);

  const openSupport = () => {
    toast.info('Email hello@joi.bot for assistance');
  };

  const handleBuySubscription = () => {
    setShowBuySubscriptionModal(true);
    closePopup();
  };

  return (
    <Popup
      ref={popupRef}
      popupX={popupX}
      className={showPopup ? 'visible' : ''}
    >
      <OptionContainer onClick={handleBuySubscription}>
        <ButtonWithIcon>
          <CreditDiamondIcon />
          Buy Subscription
        </ButtonWithIcon>
      </OptionContainer>

      <OptionContainer onClick={openSupport}>
        <ButtonWithIcon>
          <FaQuestion />
          Help
        </ButtonWithIcon>
      </OptionContainer>

      <ThinLine />

      <OptionContainer>
        <ButtonWithIcon>
          <RedditLogo />
        </ButtonWithIcon>
      </OptionContainer>

      <OptionContainer>
        <ButtonWithIcon>
          <DiscordLogo />
        </ButtonWithIcon>
      </OptionContainer>

      <ThinLine />

      {loggedInUserToken && <LogoutOption closePopup={closePopup} />}

      {!loggedInUserToken && <LoginAndSignupOptions closePopup={closePopup} />}
    </Popup>
  );
};

const ThinLine = styled.div`
  height: 0.5px;
  width: 90%;
  background-color: ${BORDER_COLOR};

  margin-left: 8px;

  margin-top: 12px;
  margin-bottom: 12px;
`;

const Popup = styled.div<{ popupX: number }>`
  position: absolute;
  top: 54px;
  left: ${({ popupX }) => popupX - 300}px;
  @media (max-width: 568px) {
    left: ${({ popupX }) => popupX - 240}px;
  }

  background-color: ${BACKGROUND_COLOR};
  border: 0.1px solid ${BORDER_COLOR};
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
  z-index: 100;

  width: 240px;

  opacity: 0;
  visibility: hidden; // Use visibility instead of display
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.4s ease, visibility 0s linear 0.4s; // Add transition for visibility
  &.visible {
    opacity: 1;
    visibility: visible; // Make element visible
    transform: translateY(0);
    transition-delay: 0s; // Remove delay when becoming visible
  }
`;

export default TopBarMenu;
