import styled from 'styled-components';
import BaseModal from '../BaseModal';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import LargeInputBox from '../../common/LargeInputBox';
import {
  DEFAULT_BOT_PERSONA,
  GREEN_COLOR,
  LIGHT_GREY_COLOR,
  PRIMARY_COLOR,
} from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';
import SingleLineInputField from '../../common/SingleLineInput';
import { useModalsContext } from '../../context/ModalsContextProvider';
import PrimaryButton from '../../common/components/PrimaryButton';
import { MdRefresh } from 'react-icons/md';
import ChatStorage from '../../common/storages/chatStorage';
import { FaCheck, FaEyeDropper } from 'react-icons/fa';
import { getHiddenGreetingMessage } from '../../pages/ChatPage/ChatPage';
import useSendMessage from '../../hooks/useSendMessage';

interface I {
  show: boolean;
  setShow: (show: boolean) => void;
}

const SettingsModal = ({ show, setShow }: I) => {
  const {
    botPersona,
    setBotPersona: setBotPersona,
    userName,
    setUserName,
    botName,
    setBotName,
    setMessages,
    setBotIsTyping,
  } = useGeneralContext();

  const { setShowAppearanceModal } = useModalsContext();
  const handleClose = () => {
    setShow(false);
  };

  const sendMessage = useSendMessage();
  const chatStorage = new ChatStorage();
  const handleStartNewChat = async () => {
    setMessages([]);
    chatStorage.clearMessages();
    handleClose();
    setBotIsTyping(true);
    const messagesToSend = [getHiddenGreetingMessage(userName)];
    const responseToMessage = await sendMessage(messagesToSend);
    if (responseToMessage) {
      console.log('response to message');
      setMessages([...messagesToSend, responseToMessage]);
    }
    setBotIsTyping(false);
  };

  const handleChangeAppearance = () => {
    setShowAppearanceModal(true);
    handleClose();
  };

  const handleResetPersonaToDefault = () => {
    setBotPersona(DEFAULT_BOT_PERSONA);
  };

  return (
    <Modal showModal={show} onRequestClose={handleClose}>
      <Container>
        <PrimaryButton onClick={handleStartNewChat}>
          <MdRefresh color={'red'} />
          Start new Chat
        </PrimaryButton>

        <SettingContainer>
          Your name:
          <InputContainer>
            <SingleLineInputField
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Type your name here"
              maxLength={20}
            />
          </InputContainer>
        </SettingContainer>

        <PrimaryButton onClick={handleChangeAppearance}>
          <FaEyeDropper />
          Change appearance
        </PrimaryButton>
        <SettingContainer>
          Bot's name:
          <InputContainer>
            <SingleLineInputField
              value={botName}
              onChange={(e) => setBotName(e.target.value)}
              placeholder="Type your bot's name here"
              maxLength={20}
            />
          </InputContainer>
        </SettingContainer>

        <PrimaryButton onClick={handleResetPersonaToDefault}>
          <MdRefresh color={'yellow'} />
          Reset Persona to default
        </PrimaryButton>
        <SettingContainer>
          The Scenario:
          <InputContainer>
            <LargeInputBox
              value={botPersona}
              setValue={setBotPersona}
              placeholder="Type your bot persona and the scenario here"
              maxLength={400}
            />
          </InputContainer>
        </SettingContainer>

        <PrimaryButton onClick={() => setShow(false)}>
          <FaCheck color={GREEN_COLOR} />
          Save & Close
        </PrimaryButton>
      </Container>
    </Modal>
  );
};

const Modal = styled(BaseModal)`
  display: flex;
  z-index: 3;
`;

const Container = styled.div`
  width: 95%;
  margin-top: 30px;
`;

const SettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;
  margin-bottom: 32px;
`;

const InputContainer = styled.div<{ withIcon?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.withIcon ? 'row' : 'column')};
  background-color: transparent;
  width: 100%;
  padding-right: 0px;
  justify-content: space-between;
  align-items: ${(props) => (props.withIcon ? 'center' : 'flex-start')};
  margin-bottom: 16px;
  z-index: 1;

  padding: 2px;

  border: 1.5px solid ${LIGHT_GREY_COLOR};
  border-radius: 8px;

  transition: border-color 0.2s;
  ${desktopMediaQuery} {
    &:hover {
      border: 1.5px solid ${PRIMARY_COLOR};
    }
  }
`;

export default SettingsModal;
