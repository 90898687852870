import React, { useRef, useState } from 'react';
import styled from 'styled-components';
//import CreditIndicatorInTopBar from '../../../../common/components/CreditIndicator';
import TopMenu from './TopMenu';
import { desktopMediaQuery } from '../../../../styleHelpers';
import { BiMenu } from 'react-icons/bi';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from '../../../../constants';

const TopRightCornerPartOfTopBar: React.FC = ({}) => {
  const [showTopRightMenu, setShowTopRightMenu] = useState(false);
  const buttonRef = useRef(null);

  const loggedInUserToken = 'asd';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showAuthModal, setShowAuthModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [authModalActiveVariant, setAuthModalActiveVariant] = useState<
    'login' | 'signup'
  >('login');

  const closePopup = () => {
    setShowTopRightMenu(false);
  };

  const defaultX = window.outerWidth ?? 1500;
  const [popupX, setPopupX] = useState(defaultX * 0.9);
  const togglePopup = () => {
    // get  mouse coordinates of the click to position the popup
    const { clientX } = window.event as MouseEvent;
    setPopupX(clientX);
    setShowTopRightMenu(!showTopRightMenu);
  };

  const handleSignUp = () => {
    setAuthModalActiveVariant('signup');
    setShowAuthModal(true);
  };

  return (
    <Container>
      {!loggedInUserToken && (
        <SignUpButton onClick={handleSignUp}>Sign Up</SignUpButton>
      )}
      <IconContainer ref={buttonRef} onClick={togglePopup}>
        <MenuIcon />
      </IconContainer>

      <TopMenu
        buttonRef={buttonRef}
        popupX={popupX}
        showPopup={showTopRightMenu}
        closePopup={closePopup}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const SignUpButton = styled.div`
  border: 1px solid ${PRIMARY_COLOR};
  color: ${PRIMARY_TEXT_COLOR};
  border-radius: 8px;
  padding: 2px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  margin-top: -2px;
  margin-bottom: -2px;
  margin-right: 8px;
  ${desktopMediaQuery} {
    margin-right: 16px;
  }
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  gap: 4px;
`;

const MenuIcon = styled(BiMenu)`
  font-size: 28px;
  ${desktopMediaQuery} {
    font-size: 42px;
  }
`;

export default TopRightCornerPartOfTopBar;
