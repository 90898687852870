import { useCookies } from 'react-cookie';
import { ChatMessage } from '../types';
import { useGeneralContext } from '../context/GeneralContextProvider';
import useApi from './useApi';
import { toast } from 'react-toastify';
import { waitSeconds } from '../helpers';

const HOW_MANY_MESSAGES_TO_KEEP_IN_MEMORY = 20;
const SECONDS_TO_WAIT = 3;
const useRequestImage = () => {
  const [{ token }] = useCookies(['token']);
  const {
    botPersona: systemMessage,
    botName,
    userName,
  } = useGeneralContext();
  const { apiCall } = useApi('sendMessage', 'POST');

  const sendMessage = async (
    newMessages: ChatMessage[],
  ): Promise<ChatMessage | null> => {
    const transformMessages = (newMessages: ChatMessage[]): ChatMessage[] => {
      // remove "type" from messages
      const fixedMessagesForSending = newMessages.map((message) => {
        const fixedMessage = { ...message };
        delete fixedMessage.type;
        return fixedMessage;
      });
      // refactor this away to server later
      const addToEndOfSystemMessage = ` This is a conversation between assistant called ${botName} and user called ${userName}`;
      const systemMessageToAppend = {
        role: 'system',
        content: systemMessage + addToEndOfSystemMessage,
      };

      const filteredMessages = fixedMessagesForSending.slice(
        -HOW_MANY_MESSAGES_TO_KEEP_IN_MEMORY,
      );

      const final = [systemMessageToAppend, ...filteredMessages];

      return final;
    };

    const messages = transformMessages(newMessages);

    await waitSeconds(SECONDS_TO_WAIT);
    const response = await apiCall({ token, messages, botName });
    const receivedMessage = response?.data;
    if (response.status !== 200 || !receivedMessage) {
      await waitSeconds(SECONDS_TO_WAIT);
      const response = await apiCall({ token, messages, botName });
      const receivedMessage = response?.data;
      if (response.status !== 200 || !receivedMessage) {
        toast.error('Something went wrong 😭');
        return null;
      }

      return receivedMessage;
    }

    return receivedMessage;
  };

  return sendMessage;
};

export default useRequestImage;
