import styled from 'styled-components';
import { BORDER_COLOR, PRIMARY_TEXT_COLOR } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';

interface ButtonConfig {
  label: string;
  onClick: () => void;
}

interface ButtonsProps {
  buttonConfigs: ButtonConfig[];
}

const OptionButtonsInOnboarding = ({ buttonConfigs }: ButtonsProps) => {
  return (
    <Container>
      {buttonConfigs.map((buttonConfig, index) => (
        <Button key={index} onClick={buttonConfig.onClick}>
          {buttonConfig.label}
        </Button>
      ))}
    </Container>
  );
};

export default OptionButtonsInOnboarding;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 12px;
  margin-right: 8px;
  gap: 8px;
`;

const Button = styled.button`
  padding: 2px 4px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid ${BORDER_COLOR};
  color: ${PRIMARY_TEXT_COLOR};
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  ${desktopMediaQuery} {
    &:hover {
      background-color: ${BORDER_COLOR};
      color: black;
    }
  }
`;
