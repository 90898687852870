import React from 'react';
import styled from 'styled-components';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../../constants';
import { getIsJoiBot } from '../../../../helpers';

interface JoiBotLogoProps {
  large?: boolean;
}

const JoiBotLogo = ({ large }: JoiBotLogoProps) => {
  // show beta text if url domain is joi.bot
  const isJoiBot = getIsJoiBot();
  const firstWordToRender = isJoiBot ? 'joi.' : 'sexting';
  const secondWordToRender = isJoiBot ? 'bot' : 'ai';
  return (
    <Container large={large}>
      <FirstWord>{firstWordToRender}</FirstWord>
      <SecondWord>{secondWordToRender}</SecondWord>
      {isJoiBot && large && <BetaText>beta</BetaText>}
    </Container>
  );
};
const Container = styled.div<{ large?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ large }) => (large ? '36px' : '24px')};
  font-family: 'BLADRMF', sans-serif;
`;

const FirstWord = styled.div`
  color: ${PRIMARY_COLOR};
`;

const SecondWord = styled.div`
  color: ${SECONDARY_COLOR};
`;

// swuare with beta text all uppercase and bolded
const BetaText = styled.div`
  color: ${SECONDARY_COLOR};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: sans-serif;

  margin-left: 8px;
  margin-bottom: 16px;
`;

export default JoiBotLogo;
