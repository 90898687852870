import styled from 'styled-components';
//import TitleAndSubtitle from './TitleAndSubtitle';
//import ExampleImage from './ExampleImage';
import CTAButton from './CTAButton';
import BasicContainerWithFadeIn from '../../common/components/BasicContainerWithFadeIn';
import { fadeIn } from '../../common/animations';
import ImagesInLanding from './ImagesInLanding';
import JoiBotLogo from '../MainApp/MainAppCommon/TopBar/JoiBotLogo';
import ExampleMessages from './ExampleMessages';
import { useEffect } from 'react';
import AudioPlayer from './AudioPlayer';
import LandingPageCard from './LandingPageCard';
import Footer from '../MainApp/MainAppCommon/Footer';
import { DiscordLogo, RedditLogo } from '../../common/components/SocialLogos';
import { DEFAULT_GIFS } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';
import FirstTimeUserStorage from '../../common/storages/firstTimeUserStorage';
import { useNavigate } from 'react-router-dom';
import { APP_PATH } from '../../pathNames';
import SocialProofStars from './SocialProofStars';
import { getIsJoiBot } from '../../helpers';

const LandingPage = () => {
  const firstTimeUserStorage = new FirstTimeUserStorage();

  useEffect(() => {
    // Add styles via a hackish way
    const styleLink = document.createElement('link');
    styleLink.rel = 'stylesheet';
    styleLink.href = '/LandingPage.css';
    document.head.appendChild(styleLink);

    // remove when not in landing page
    return () => {
      document.head.removeChild(styleLink);
    };
  }, []);

  const navigate = useNavigate();
  // check if first time, if yes, redirect to app page
  useEffect(() => {
    if (!firstTimeUserStorage.isFirstTimeUser()) {
      firstTimeUserStorage.setNotFirstTimeUser();
      navigate(APP_PATH);
    }
  }, []);

  const isJoi = getIsJoiBot();

  const nameToUse = isJoi ? 'Joi' : 'SextingAI';
  const firstCardTitle = `What is ${nameToUse}`;
  const firstCardDesc = `${nameToUse} is an uncensored AI chatbot. You can use it to keep you company, talk about your feelings, or just have a chat. Sexting is encouraged 🙈`;

  const secondTitle = `Who made ${nameToUse}`;
  const secondDesc =
    'Developed by a company based in Finland, Europe. We prioritize security and do not save conversations. Our security practices are informed by experience working with billion-dollar companies. We also value mental health and aim to support individuals who need someone to talk to';

  const thirdTitle = 'Free sexting AI bot';
  const thirdDesc =
    "You heard it right, a free sexting AI chatbot. You can talk about anything you want, and she will respond. Do limitless AI Roleplay 🍆🌮 You can also have a normal conversation with them. It's up to you.";

  const fourthTitle = 'AI Sexting app';
  const fourthDesc =
    'Get the AI girlfriend online free - the perfect experience when you quickly need someone to simulate a hot relationship with. Or do whatever you want to!';

  const fifthTitle = 'NSFW AI Chat';
  const fifthDesc =
    "It's like an uncensored NSFW ChatGPT, no restrictions. You will love it, we promise you that 💘";

  const ENABLE_AUDIO_PLAYER = false;

  return (
    <Container>
      <JoiBotLogo large />
      <FirstContainer>
        <ImagesInLanding />
        {ENABLE_AUDIO_PLAYER && <AudioPlayer src="/home.mp3" />}
        <ExampleMessages />
      </FirstContainer>
      <CTAButton />
      <LandingPageCard
        title={firstCardTitle}
        description={firstCardDesc}
        images={[DEFAULT_GIFS[0]]}
      />
      <CTAButton />
      <LandingPageCard
        title={secondTitle}
        description={secondDesc}
        alternateOrder
        images={[DEFAULT_GIFS[1]]}
      />

      <SocialProofStars />
      <CTAButton />
      <LandingPageCard
        title={thirdTitle}
        description={thirdDesc}
        images={[DEFAULT_GIFS[2]]}
      />
      <CTAButton />
      <LandingPageCard
        title={fourthTitle}
        description={fourthDesc}
        alternateOrder
        images={[DEFAULT_GIFS[3]]}
      />
      <CTAButton />
      <LandingPageCard
        title={fifthTitle}
        description={fifthDesc}
        images={[DEFAULT_GIFS[4]]}
      />
      <SocialLogosContainer>
        <RedditLogo bigger />
        <DiscordLogo bigger />
      </SocialLogosContainer>
      <Footer />
    </Container>
  );
};

const Container = styled(BasicContainerWithFadeIn)`
  width: 92%;
  animation: ${fadeIn} 1.5s ease-in-out;
  margin-top: 16px;

  padding-left: 8px;
`;

const FirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const SocialLogosContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${desktopMediaQuery} {
    flex-direction: row;
  }
  gap: 16px;
  margin-top: 16px;
`;

export default LandingPage;
