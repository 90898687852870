import React from 'react';
import StrongButton from '../../common/components/StrongButton';
import { useNavigate } from 'react-router-dom';
import { APP_PATH } from '../../pathNames';
import styled from 'styled-components';
import { getIsJoiBot, scrollToTopSmoothly } from '../../helpers';
import { desktopMediaQuery } from '../../styleHelpers';
import { RiChat3Fill } from 'react-icons/ri';
import { SECONDARY_TEXT_COLOR } from '../../constants';
import { FaLock } from 'react-icons/fa';

interface CTAButtonProps {
  alternativeText?: boolean;
}

const CTAButton: React.FC<CTAButtonProps> = ({ alternativeText }) => {
  const navigate = useNavigate();

  const isJoi = getIsJoiBot();

  const handleClick = () => {
    if (isJoi) {
      window.location.href = 'https://SextingAI.net/app';
      return;
    }
    navigate(APP_PATH);
    scrollToTopSmoothly();
  };

  return (
    <Container>
      <StrongButton onClick={handleClick}>
        {alternativeText ? '✨ ' : <RiChat3Fill />}

        {alternativeText ? 'Try now' : 'Start chat'}
      </StrongButton>
      <SubText>
        <LockIcon />
        Private & no sign-up
      </SubText>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 300px;
  max-height: 50px;

  margin-top: 24px;

  ${desktopMediaQuery} {
    width: 450px;
    margin-top: 24px;
  }
`;

const SubText = styled.p`
  display: flex;
  font-size: 14px;
  color: ${SECONDARY_TEXT_COLOR};

  margin-top: 8px;
`;

const LockIcon = styled(FaLock)`
  margin-top: 2px;
  margin-right: 4px;
`;
export default CTAButton;
