class UserSettingsStorage {
  private storageKey: string;

  private usernameKey: string;
  private botNameKey: string;
  private chosenImageKey: string;
  private botPersonaKey: string;

  constructor() {
    this.storageKey = 'userSettings';
    this.usernameKey = 'userName';
    this.botNameKey = 'botName';
    this.chosenImageKey = 'chosenImage';
    this.botPersonaKey = 'botPersona';
  }

  getUserName(): string {
    const fullKey = `${this.storageKey}.${this.usernameKey}`;
    const userName = localStorage.getItem(fullKey);
    return userName ? JSON.parse(userName) : null;
  }

  setUserName(userName: string): void {
    const fullKey = `${this.storageKey}.${this.usernameKey}`;
    localStorage.setItem(fullKey, JSON.stringify(userName));
  }

  getBotName(): string {
    const fullKey = `${this.storageKey}.${this.botNameKey}`;
    const botName = localStorage.getItem(fullKey);
    return botName ? JSON.parse(botName) : null;
  }

  setBotName(botName: string): void {
    const fullKey = `${this.storageKey}.${this.botNameKey}`;
    localStorage.setItem(fullKey, JSON.stringify(botName));
  }

  getChosenImage(): string {
    const fullKey = `${this.storageKey}.${this.chosenImageKey}`;
    const chosenImage = localStorage.getItem(fullKey);
    return chosenImage ? JSON.parse(chosenImage) : null;
  }

  setChosenImage(chosenImage: string): void {
    const fullKey = `${this.storageKey}.${this.chosenImageKey}`;
    localStorage.setItem(fullKey, JSON.stringify(chosenImage));
  }

  getBotPersona(): string {
    const fullKey = `${this.storageKey}.${this.botPersonaKey}`;
    const botPersona = localStorage.getItem(fullKey);
    return botPersona ? JSON.parse(botPersona) : null;
  }

  setBotPersona(botPersona: string): void {
    const fullKey = `${this.storageKey}.${this.botPersonaKey}`;
    localStorage.setItem(fullKey, JSON.stringify(botPersona));
  }

  getSettings(): {
    userName: string;
    botName: string;
    chosenImage: string;
    botPersona: string;
  } {
    return {
      userName: this.getUserName(),
      botName: this.getBotName(),
      chosenImage: this.getChosenImage(),
      botPersona: this.getBotPersona(),
    };
  }
}

export default UserSettingsStorage;
