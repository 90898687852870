import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaCog } from 'react-icons/fa';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { desktopMediaQuery } from '../../styleHelpers';
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from 'react-icons/md';
import { fadeInWithBounce } from '../../common/animations';
import { useModalsContext } from '../../context/ModalsContextProvider';
import UserSettingsStorage from '../../common/storages/userSettingsStorage';
import { DEFAULT_IMAGE_TO_USE, PRIMARY_COLOR } from '../../constants';

const TopBarInConversation = () => {
  const { chosenImage, setChosenImage, botName, isFocusedOnKeyboard } =
    useGeneralContext();
  const { setShowSettingsModal, setShowImagesModal } = useModalsContext();
  const [showBiggerImage, setShowBiggerImage] = useState(true);

  const [imgToRender, setImgToRender] = useState(chosenImage);

  /*
  const clickBack = () => {
    window.history.back();
  };
  <BackButton onClick={clickBack} />
*/

  const handleShowImages = () => {
    setShowImagesModal(true);
  };

  const openSettingsModal = () => {
    setShowSettingsModal(true);
  };

  const userSettingsStorage = new UserSettingsStorage();
  useEffect(() => {
    const chosenImage = userSettingsStorage.getChosenImage();
    if (chosenImage === null) {
      setChosenImage(DEFAULT_IMAGE_TO_USE);
      setImgToRender(DEFAULT_IMAGE_TO_USE);
      return;
    }
    setChosenImage(chosenImage);
    setImgToRender(chosenImage ?? DEFAULT_IMAGE_TO_USE);
  }, []);

  useEffect(() => {
    if (chosenImage !== '') {
      setImgToRender(chosenImage);
    }
  }, [chosenImage]);
  return (
    <>
      <TopBarContainer isFocusedOnKeyboard={isFocusedOnKeyboard}>
        {showBiggerImage ? (
          <ContainerForBigImage>
            <BiggerBotImage src={imgToRender} onClick={handleShowImages} />
            <MakeImageSmallerIcon onClick={() => setShowBiggerImage(false)} />
          </ContainerForBigImage>
        ) : (
          <ContainerForSmallerImage>
            <BotImage src={imgToRender} onClick={handleShowImages} />
            <BotName onClick={handleShowImages}>{botName}</BotName>
            <MakeImageBiggerIcon onClick={() => setShowBiggerImage(true)} />
          </ContainerForSmallerImage>
        )}

        <SettingsIcon
          onClick={openSettingsModal}
          showBiggerImage={showBiggerImage}
        />
      </TopBarContainer>
    </>
  );
};

const TopBarContainer = styled.div<{ isFocusedOnKeyboard: boolean }>`
  display: flex;
  align-items: center;
  margin-top: ${(props) => (props.isFocusedOnKeyboard ? '12px' : '12px')};
  padding-top: 8px;
  padding-bottom: 4px;
  border-bottom: 1px solid black;

  width: 98%;

  position: sticky;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars

const ContainerForBigImage = styled.div`
  position: relative;
  width: 99vw;
  display: flex;
  justify-content: center;

  margin-left: 30px;

  animation: ${fadeInWithBounce} 0.1s ease;

  margin-bottom: 4px;

  z-index: 10;
`;

const MakeImageSmallerIcon = styled(MdKeyboardDoubleArrowUp)`
  position: absolute;
  bottom: 8px;
  right: 8px;
  font-size: 24px;
  z-index: 3;

  cursor: pointer;
`;

const BotImage = styled.img`
  object-fit: cover;
  object-position: top;
  width: 32px;
  height: 32px;
  ${desktopMediaQuery} {
    width: 64px;
    height: 64px;
  }

  border-radius: 50%;
  border: 1px solid ${PRIMARY_COLOR};
  margin-right: 8px;

  cursor: pointer;
`;

const BiggerBotImage = styled(BotImage)`
  width: 160px;
  height: 160px;

  ${desktopMediaQuery} {
    width: 250px;
    height: 250px;
  }

  border-radius: 16px;
  z-index: 10;
`;

const ContainerForSmallerImage = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;

  cursor: pointer;

  animation: ${fadeInWithBounce} 0.1s ease;
`;

const MakeImageBiggerIcon = styled(MdKeyboardDoubleArrowDown)`
  font-size: 22px;
  margin-left: 8px;
`;
const SettingsIcon = styled(FaCog)<{ showBiggerImage: boolean }>`
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  margin-bottom: 2px;

  z-index: 11;

  font-size: ${(props) => (props.showBiggerImage ? '24px' : '18px')};
`;
const BotName = styled.div`
  font-size: 16px;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars

export default TopBarInConversation;
