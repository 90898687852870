import React from 'react';
import styled from 'styled-components';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  background: linear-gradient(
    to right bottom,
    ${PRIMARY_TEXT_COLOR} 0%,
    ${PRIMARY_COLOR} 50%,
    ${PRIMARY_COLOR} 100%
  );
  color: ${PRIMARY_TEXT_COLOR};
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s;

  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);

  &:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.7);
  }

  width: 100%;
`;

const StyledButton = styled.button`
  display: flex;
  justify-content: center;

  gap: 8px;
  border: 3px solid black;
  background-color: ${PRIMARY_COLOR};
  color: ${PRIMARY_TEXT_COLOR};
  
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px;

  font-weight: bold;
  border-radius: 16px;

  box-shadow: 3px 3px 5px rgba(255, 255, 255, 0.4);

  font-family: Arial, sans-serif;
  text-align: center;
  
  user-select: none;
  
  transition: transform 0.3s ease;
  
  width: 100%;

  font-size: 16px;
  ${desktopMediaQuery} {
    font-size: 18px;
  }

  cursor: pointer;
  &:hover {
    transform: translateY(-2px);
  }
`;

// Define the props interface for your component
interface ButtonProps {
  children: React.ReactNode;
  onClick: () => void;
}

const StrongButton: React.FC<ButtonProps> = ({ children, onClick }) => {
  return <StyledButton onClick={onClick}>{children}</StyledButton>;
};

export default StrongButton;
