import { useCookies } from 'react-cookie';
import { ChatMessage } from '../types';
import { useGeneralContext } from '../context/GeneralContextProvider';
import useApi from './useApi';
import { toast } from 'react-toastify';
import { waitSeconds } from '../helpers';

const HOW_MANY_MESSAGES_TO_KEEP_IN_MEMORY = 20;
const SECONDS_TO_WAIT = 2;
const useSendMessage = () => {
  const [{ token }] = useCookies(['token']);
  const { userName, botName, botPersona } = useGeneralContext();
  const { apiCall } = useApi('sendMessage', 'POST');

  const sendMessage = async (
    newMessages: ChatMessage[],
    chatScenario?: string,
  ): Promise<ChatMessage | null> => {
    const transformMessages = (newMessages: ChatMessage[]): ChatMessage[] => {
      const fixedMessagesForSending = newMessages.map((message) => {
        const fixedMessage = { ...message };
        delete fixedMessage.type;
        return fixedMessage;
      });

      const filteredMessages = fixedMessagesForSending.slice(
        -HOW_MANY_MESSAGES_TO_KEEP_IN_MEMORY,
      );

      return filteredMessages;
    };

    const messages = transformMessages(newMessages);

    const body = {
      messages,
      botName,
      userName,
      botPersona: chatScenario || botPersona,
      token,
    };

    await waitSeconds(SECONDS_TO_WAIT);
    const response = await apiCall(body);
    const receivedMessage = response?.data;
    if (
      response.status !== 200 ||
      !receivedMessage ||
      receivedMessage?.content === ''
    ) {
      await waitSeconds(SECONDS_TO_WAIT);
      const response = await apiCall(body);
      const receivedMessage = response?.data;
      if (response.status !== 200 || !receivedMessage) {
        toast.error('Something went wrong 😭');
        return null;
      }

      return receivedMessage;
    }

    return receivedMessage;
  };

  return sendMessage;
};

export default useSendMessage;
