import { Routes as Switch, Route } from 'react-router-dom';
import MainAppContainer from './pages/MainApp/MainAppContainer';
import ContentPolicyPage from './pages/MainApp/MainAppCommon/ContentPolicyPage';
import NotFound from './common/components/NotFound';
import PrivacyPolicyPage from './pages/TermsEtc/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsEtc/TermsOfServicePage';
import UsePolicyPage from './pages/TermsEtc/UsePolicyPage';
import { PRIVACY_POLICY_URL, USE_POLICY_URL, TERMS_URL } from './pathNames';
import LandingPage from './pages/LandingPage/LandingPage';
import ChatPage from './pages/ChatPage/ChatPage';

//<Route path="/blade-runner-2049-ai-girlfriend" element={<LandingPageBladeRunner />} />
const Routes = () => {
  return (
    <Switch>
      <Route index element={<LandingPage />} />
      <Route path="/content-policy" element={<ContentPolicyPage />} />
      <Route path="/app" element={<MainAppContainer />}>
        <Route index element={<ChatPage />} />
      </Route>
      <Route path={PRIVACY_POLICY_URL} element={<PrivacyPolicyPage />} />
      <Route path={USE_POLICY_URL} element={<UsePolicyPage />} />
      <Route path={TERMS_URL} element={<TermsOfServicePage />} />
      <Route path="*" element={<NotFound />} />
    </Switch>
  );
};

export default Routes;
