// src/components/SubscriptionCard.tsx
import React from 'react';
import styled from 'styled-components';
import {
  CARD_BACKGROUND_COLOR,
  PRIMARY_COLOR,
  BORDER_COLOR,
  PRIMARY_TEXT_COLOR,
  ORANGE_LINK_COLOR,
  SECONDARY_COLOR,
} from '../../constants';

interface SubscriptionCardProps {
  variation: 'free' | 'silver' | 'gold' | 'platinum';
}

interface SubscriptionData {
  name: string;
  price: string;
  discount?: string;
  benefits: string[];
  isFeatured?: boolean;
}

const subscriptionData: Record<string, SubscriptionData> = {
  free: {
    name: 'Copper',
    price: 'Free',
    benefits: ['Unlimited, for now!'],
  },
  /*
  silver: {
    name: 'Silver',
    price: '$8.99/month',
    benefits: ['75 messages per day', '= 2250 messages per month'],
  },
  gold: {
    name: 'Gold',
    price: '$14.99/month',
    discount: 'Save 17%',
    benefits: ['150 messages per day', '= 4500 messages per month'],
    isFeatured: true,
  },
  platinum: {
    name: 'Platinum',
    price: '$29.99/month',
    discount: 'Save 38%',
    benefits: ['400 messages per day', '= 12,000 messages per month'],
  },
  */
};

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ variation }) => {
  const data = subscriptionData[variation];

  const handleClick = () => {
    console.log(`Subscribing to ${data.name}`);
  };
  return (
    <CardContainer featured={data.isFeatured}>
      {data.isFeatured && <FeaturedBadge>Most Popular</FeaturedBadge>}
      <CardHeader>{data.name}</CardHeader>
      <PriceSection>
        <Price>{data.price}</Price>
        {data.discount && <DiscountBadge>{data.discount}</DiscountBadge>}
      </PriceSection>
      <BenefitsList>
        {data.benefits.map((benefit, index) => (
          <BenefitItem key={index}>{benefit}</BenefitItem>
        ))}
      </BenefitsList>
      <SubscribeButton variant={variation} onClick={handleClick}>
        {variation === 'free' ? 'Get Started' : 'Subscribe'}
      </SubscribeButton>
    </CardContainer>
  );
};

export default SubscriptionCard;

const CardContainer = styled.div<{ featured?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  background: ${CARD_BACKGROUND_COLOR};
  border: ${({ featured }) =>
    featured ? `2px solid ${PRIMARY_COLOR}` : `1px solid ${BORDER_COLOR}`};
  border-radius: 8px;
  padding: 16px;
  margin: 8px;
  margin-left: 0px;
  width: 280px;
  max-width: 75vw;
  box-shadow: ${({ featured }) =>
    featured
      ? `0 4px 20px rgba(30, 144, 255, 0.2)`
      : `0 2px 10px rgba(0, 0, 0, 0.1)`};

  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ featured }) =>
      featured
        ? `0 6px 25px rgba(30, 144, 255, 0.3)`
        : `0 4px 15px rgba(0, 0, 0, 0.15)`};
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const FeaturedBadge = styled.div`
  background: ${PRIMARY_COLOR};
  color: ${PRIMARY_TEXT_COLOR};
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 12px;
  position: absolute;
  top: 4px;
  left: 2px;
  z-index: 1;
`;

const CardHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 16px;
  color: ${PRIMARY_TEXT_COLOR};
`;

const PriceSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 2px;
`;

const Price = styled.span`
  font-size: 28px;
  font-weight: bold;
  color: ${PRIMARY_TEXT_COLOR};
`;

const DiscountBadge = styled.span`
  background: ${ORANGE_LINK_COLOR};
  color: black;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  margin-left: 8px;
`;

const BenefitsList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 24px;
  width: 100%;
`;

const BenefitItem = styled.li`
  font-size: 16px;
  padding: 8px 0;
  text-align: center;
  color: ${PRIMARY_TEXT_COLOR};
`;

const SubscribeButton = styled.button<{ variant: string }>`
  background: ${({ variant }) =>
    variant === 'free' ? SECONDARY_COLOR : PRIMARY_COLOR};
  color: ${PRIMARY_TEXT_COLOR};
  border: none;
  border-radius: 24px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: ${({ variant }) =>
      variant === 'free' ? PRIMARY_COLOR : SECONDARY_COLOR};
    color: ${({ variant }) =>
      variant === 'free' ? PRIMARY_TEXT_COLOR : 'black'};
  }
`;
