import { FaPaperPlane } from 'react-icons/fa';
import styled from 'styled-components';
import SingleLineInputField from '../../common/SingleLineInput';
import { LIGHT_GREY_COLOR, SECONDARY_COLOR, SECONDARY_TEXT_COLOR } from '../../constants';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { getIsOnDesktop } from '../../styleHelpers';
import { waitSeconds } from '../../helpers';
import ExtraOptionsIconAndMenu from './ExtraOptionsIconAndMenu';
import { useRef } from 'react';

const MAX_MESSAGE_LENGTH = 200;

interface ChatInputProps {
  messageToWrite: string;
  setMessageToWrite: (message: string) => void;
  handleSendMessage: () => void;
  placeholder: string;
  disableInput?: boolean;
}

const ChatInput = ({
  messageToWrite,
  setMessageToWrite,
  handleSendMessage,
  placeholder,
  disableInput,
}: ChatInputProps) => {
  const { isFocusedOnKeyboard, setIsFocusedOnKeyboard, isDoingAction, botIsTyping } =
    useGeneralContext();
  const emphasizeInput = placeholder === 'Write only your name...';

  const isOnDesktop = getIsOnDesktop();
  const onFocus = async () => {
    if (isOnDesktop) return;
    await waitSeconds(0.2);
    setIsFocusedOnKeyboard(true);
  };

  const onBlur = async () => {
    await waitSeconds(0.3);
    setIsFocusedOnKeyboard(false);
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const handleFocusOnInput = () => {
    setIsFocusedOnKeyboard(true);
    inputRef.current?.focus();
  };

  return (
    <InputContainer
      emphasizeInput={emphasizeInput}
      isFocusedOnKeyboard={isFocusedOnKeyboard}
    >
      <ExtraOptionsIconAndMenu handleFocusOnInput={handleFocusOnInput} />
      <IsDoingActionStar isDoingAction={isDoingAction} />
      <StyledInputField
        ref={inputRef}
        disabled={disableInput}
        emphasizeInput={emphasizeInput}
        placeholder={placeholder}
        value={messageToWrite}
        onChange={(e) => setMessageToWrite(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSendMessage();
          }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={MAX_MESSAGE_LENGTH}
        autoComplete="off"
      />
      <IsDoingActionStar isDoingAction={isDoingAction} />
      <ContainerForSendingButton>
        <PaperPlaneIcon onClick={handleSendMessage} botIsTyping={botIsTyping} />
      </ContainerForSendingButton>
    </InputContainer>
  );
};

export default ChatInput;

const IsDoingActionStar = ({ isDoingAction }: { isDoingAction: boolean }) => {
  if (isDoingAction) {
    return (
      <div
        style={{
          color: SECONDARY_COLOR,
          fontSize: '20px',
          marginRight: '8px',
          marginLeft: '8px',
        }}
      >
        *
      </div>
    );
  }
  return null;
};

const InputContainer = styled.div<{
  emphasizeInput: boolean;
  isFocusedOnKeyboard: boolean;
}>`
  position: sticky;
  bottom: 0;

  display: flex;
  align-items: center;
  width: 97%;

  border: 1.5px solid
    ${(props) => (props.emphasizeInput ? SECONDARY_COLOR : LIGHT_GREY_COLOR)};

  border-radius: 8px;
  margin-left: 6px;

  margin-bottom: ${(props) => (props.isFocusedOnKeyboard ? '-8px' : '16px')};
`;
const StyledInputField = styled(SingleLineInputField)<{
  emphasizeInput: boolean;
}>`
  margin-right: 8px;
  margin-left: -2px;
  padding-bottom: 14px;
  ::placeholder {
    color: ${(props) => props.emphasizeInput && SECONDARY_COLOR};
    font-weight: ${(props) => (props.emphasizeInput ? 'bold' : 'normal')};
  }
`;

const ContainerForSendingButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`;

const PaperPlaneIcon = styled(FaPaperPlane)<{ botIsTyping: boolean }>`
  color: ${(props) => (props.botIsTyping ? SECONDARY_TEXT_COLOR : SECONDARY_COLOR)};
  font-size: 22px;
  cursor: pointer;
`;
