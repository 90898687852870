import { useEffect, useState } from 'react';
import styled from 'styled-components';

import useSendMessage from '../../hooks/useSendMessage';
import TopBarInConversation from './TopBarInConversation';
import ChatMessages from './ChatMessages';
import { ChatMessage } from '../../types';
import ChatInput from './ChatInput';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { waitSeconds } from '../../helpers';
import UserSettingsStorage from '../../common/storages/userSettingsStorage';
import {
  assistantRole,
  DEFAULT_BOT_NAME,
  DEFAULT_BOT_PERSONA,
  GIRLFRIEND_PERSONA,
  HIDDEN_TYPE_MSG,
  HORNY_PERSONA,
  JERK_OFF_INSTRUCTIONS,
  ONBOARDING_TYPE_MSG,
  userRole,
} from '../../constants';
import ChatStorage from '../../common/storages/chatStorage';
import OptionButtonsInOnboarding from './OptionButtonsInOnboarding';
import { useModalsContext } from '../../context/ModalsContextProvider';
import { cleanUpUserName } from './chatPageHelpers';

const getGreetingMessage = () => ({
  role: assistantRole,
  content: `Hiiii~ What's your name?`,
  type: ONBOARDING_TYPE_MSG,
});

const getSecondGreetingMessage = (userName: string, botName: string) => ({
  role: assistantRole,
  content: `Hey there, ${userName}! I'm ${botName}. Want to customize my look?`,
  type: ONBOARDING_TYPE_MSG,
});

const getThirdGreetingMessage = () => ({
  role: assistantRole,
  content: `Feel free to change our names or appearance anytime in the settings!`,
  type: ONBOARDING_TYPE_MSG,
});

const getFourthGreetingMessage = () => ({
  role: assistantRole,
  content: `Which scenario should we start with? You can always switch it up later!`,
  type: ONBOARDING_TYPE_MSG,
});

const getOptionalCustomScenarioMessage = () => ({
  role: assistantRole,
  content: 'Write the custom scenario and my personality here',
  type: ONBOARDING_TYPE_MSG,
});

export const getHiddenGreetingMessage = (userName: string) => ({
  role: userRole,
  content: `Greet ${userName} in a way that fits the scenario and bot's persona. Make it engaging so the user wants to answer to you. Don't say "it's me.." or "gorgeous", don't make it too short. Don't break character.`,
  type: HIDDEN_TYPE_MSG,
});

const hiddenWelcomeBackMessage = () => ({
  role: userRole,
  content: 'This is a new chat between the user and assistant',
  type: HIDDEN_TYPE_MSG,
});

const welcomeBackMessage = (userName: string) => ({
  role: assistantRole,
  content: `Hii again ${userName}! What's up? 😊`,
  type: ONBOARDING_TYPE_MSG,
});

const ChatPage = () => {
  const sendMessage = useSendMessage();
  const {
    messages,
    setMessages,
    botIsTyping,
    setBotIsTyping,
    isFocusedOnKeyboard,
    userName,
    setUserName,
    botName,
    setBotName,
    setBotPersona: setChatScenario,
    isDoingAction,
    setIsDoingAction,
  } = useGeneralContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setShowAppearanceModal, setShowSettingsModal } = useModalsContext();
  const [isAskingToChangeScenario, setIsAskingToChangeScenario] =
    useState(false);

  const [chatInputValue, setChatInputValue] = useState('');

  const chatStorage = new ChatStorage();

  const [isAskingToChangeAppearance, setIsAskingToChangeAppearance] =
    useState(false);
  const [isAskingToChangePersona, setIsAskingToChangePersona] = useState(false);

  const userSettingsStorage = new UserSettingsStorage();
  const [isAskingForName, setIsAskingForName] = useState(false);
  useEffect(() => {
    const userSettings = userSettingsStorage.getSettings();
    const { userName, botName, botPersona } = userSettings;
    const hasntSetupName = userName === '' || userName === null;
    if (hasntSetupName) {
      setIsAskingForName(true);
    }

    if (userSettings) {
      setUserName(userName || '');
      setBotName(botName || DEFAULT_BOT_NAME);
      setChatScenario(botPersona || DEFAULT_BOT_PERSONA);
    }

    if (messages.length > 0) return;

    const messagesFromStorage = chatStorage.getMessages();
    if (hasntSetupName) {
      const greetingMessage = getGreetingMessage();
      setMessages([greetingMessage]);
    } else if (messagesFromStorage.length > 0) {
      const welcomeBack = welcomeBackMessage(userName);
      setMessages([
        ...messagesFromStorage,
        hiddenWelcomeBackMessage(),
        welcomeBack,
      ]);
    } else {
      const welcomeBack = welcomeBackMessage(userName);
      setMessages([welcomeBack]);
    }
  }, []);

  const disableInput = isAskingToChangeAppearance || isAskingToChangePersona;

  const getPlaceholderToRender = () => {
    if (isAskingForName) return 'Write only your name...';
    if (disableInput) return 'Choose an option ☝️';
    if (isDoingAction) return 'Write an action...';
    // if custom scenario
    if (isAskingToChangeScenario)
      return "Type custom scenario and bot's personality...";
    return 'Write a message...';
  };

  const placeHolderToRender = getPlaceholderToRender();

  const handleSettingCustomScenario = async (scenario: string) => {
    setIsAskingToChangeScenario(false);
    setChatScenario(scenario);
    setChatInputValue('');
    await waitSeconds(0.3);
    // Next just ask the assistant to greet the user
    const messagesToSend = [getHiddenGreetingMessage(userName)];
    console.log('added hidden greeting message');
    setBotIsTyping(true);
    console.log('start setting sending message');
    const responseToMessage = await sendMessage(messagesToSend, scenario);
    console.log(scenario)
    if (responseToMessage) {
      console.log('response to message');
      setMessages([...messagesToSend, responseToMessage]);
    }
    setBotIsTyping(false);
    console.log("finished setting custom scenario, now let's greet the user");
  };

  // TODO: refactor so you only add the new message to to sendMessage AND all messages
  const handleSendMessage = async () => {
    if (isAskingToChangeScenario) {
      console.log(chatInputValue);
      handleSettingCustomScenario(chatInputValue);
      return;
    }

    if (chatInputValue.trim() === '') {
      alert('Please write a message');
      return;
    }
    if (botIsTyping) return;

    let finalMessage = chatInputValue;

    if (isDoingAction) {
      finalMessage = `*${chatInputValue}*`;
      setIsDoingAction(false);
    }

    const messageToAdd: ChatMessage = {
      role: userRole,
      content: finalMessage,
    };

    const newMessages = [...messages, messageToAdd];
    setMessages(newMessages);
    setChatInputValue('');

    if (isAskingForName) {
      handleFirstTime();
      return;
    }

    await waitSeconds(0.3);
    setBotIsTyping(true);

    chatStorage.addMessage(messageToAdd);

    const responseToMessage = await sendMessage(newMessages);
    if (responseToMessage) {
      const messagesWithResponse = [...newMessages, responseToMessage];
      chatStorage.addMessage(responseToMessage);
      setMessages(messagesWithResponse);
    }

    setBotIsTyping(false);
  };

  //////////////////////////////
  // Onboarding related stuff //
  const handleFirstTime = async () => {
    await waitSeconds(0.5);
    const cleanUpUserNameMessage = cleanUpUserName(chatInputValue);
    setUserName(cleanUpUserNameMessage);
    setMessages((prev) => [
      ...prev,
      getSecondGreetingMessage(cleanUpUserNameMessage, botName),
    ]);
    setBotIsTyping(false);
    setIsAskingForName(false);
    setIsAskingToChangeAppearance(true);
  };

  const handleGoToNextStepAfterAppearance = async () => {
    await waitSeconds(0.2);
    setMessages((prev) => [...prev, getThirdGreetingMessage()]);
    setIsAskingToChangeAppearance(false);
    await waitSeconds(1.5);
    setIsAskingToChangePersona(true);
    setMessages((prev) => [...prev, getFourthGreetingMessage()]);
  };

  const handleClickingYesOnAppearance = async () => {
    setShowAppearanceModal(true);
    handleGoToNextStepAfterAppearance();
  };

  const appearanceButtonsConfig = [
    { label: 'Yes', onClick: handleClickingYesOnAppearance },
    { label: 'No', onClick: handleGoToNextStepAfterAppearance },
  ];

  const handleChoosingScenario = async (persona: string) => {
    // dirty hack ew
    let chatScenario = '';
    if (persona === 'girlfriend') {
      setChatScenario(GIRLFRIEND_PERSONA);
      chatScenario = GIRLFRIEND_PERSONA;
    } else if (persona === 'horny') {
      setChatScenario(HORNY_PERSONA);
      chatScenario = HORNY_PERSONA;
    } else if (persona === 'joi') {
      setChatScenario(JERK_OFF_INSTRUCTIONS);
      chatScenario = JERK_OFF_INSTRUCTIONS;
    } else if (persona === 'custom') {
      setMessages((prev) => [...prev, getOptionalCustomScenarioMessage()]);
      setIsAskingToChangePersona(false);
      setIsAskingToChangeScenario(true);
      return; // early and put the fifthGreeting msg later
    } else {
      setChatScenario(DEFAULT_BOT_PERSONA);
    }

    await waitSeconds(0.3);
    // Next just ask the assistant to greet the user
    const messagesToSend = [getHiddenGreetingMessage(userName)];
    console.log('added hidden greeting message');
    setBotIsTyping(true);
    console.log('start setting sending message');
    const responseToMessage = await sendMessage(messagesToSend, chatScenario);
    if (responseToMessage) {
      console.log('response to message');
      setMessages([...messagesToSend, responseToMessage]);
    }
    setBotIsTyping(false);
    console.log("finished setting custom scenario, now let's greet the user");
    setIsAskingToChangePersona(false);
  };

  const personaButtonsConfig = [
    { label: 'Default', onClick: () => handleChoosingScenario('default') },
    {
      label: 'Jerk-off instructions',
      onClick: () => handleChoosingScenario('joi'),
    },
    {
      label: 'Horny girlfriend',
      onClick: () => handleChoosingScenario('horny'),
    },
    { label: 'Custom', onClick: () => handleChoosingScenario('custom') },
  ];

  // END of Onboarding related stuff //

  useEffect(() => {
    const goUpWhenKeyboardIsFocused = () => {
      if (isFocusedOnKeyboard) {
        window.scrollTo(1000, 0);
      }
    };
    goUpWhenKeyboardIsFocused();
  }, [isFocusedOnKeyboard]);

  const [heightWhenOpeningMounting, setHeightWhenOpeningMounting] = useState(0);
  useEffect(() => {
    if (heightWhenOpeningMounting !== 0) return;

    const height = window.innerHeight;
    setHeightWhenOpeningMounting(height);
  }, []);

  return (
    <Container
      height={heightWhenOpeningMounting}
      isFocusedOnKeyboard={isFocusedOnKeyboard}
    >
      <TopBarInConversation />

      <ChatMessages messages={messages} botIsTyping={botIsTyping} />

      {isAskingToChangeAppearance && (
        <OptionButtonsInOnboarding buttonConfigs={appearanceButtonsConfig} />
      )}

      {isAskingToChangePersona && (
        <OptionButtonsInOnboarding buttonConfigs={personaButtonsConfig} />
      )}

      <ChatInput
        messageToWrite={chatInputValue}
        setMessageToWrite={setChatInputValue}
        handleSendMessage={handleSendMessage}
        disableInput={disableInput}
        placeholder={placeHolderToRender}
      />
    </Container>
  );
};

const Container = styled.div<{ height: number; isFocusedOnKeyboard: boolean }>`
  display: flex;
  flex-direction: column;

  width: 100%;

  height: ${({ height }) => height - 80}px; // 81vh
  max-height: 1000px;

  max-width: 800px;

  overflow-y: hidden;

  padding-bottom: ${({ isFocusedOnKeyboard }) =>
    isFocusedOnKeyboard ? '-8px' : '16px'};

  // if focused 12px, 2px
  margin-top: ${({ isFocusedOnKeyboard }) =>
    isFocusedOnKeyboard ? '12px' : '2px'};
`;

export default ChatPage;
