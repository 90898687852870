import React from 'react';
import { FaEdit, FaPlay, FaRedo } from 'react-icons/fa';
import styled from 'styled-components';

interface ActionButtonsInMessageProps {
  handleRetryMessage: () => void;
  handleEditMessage: () => void;
  handleContinue: () => void;
}

const ActionButtonsInMessage: React.FC<ActionButtonsInMessageProps> = ({
  handleRetryMessage,
  handleEditMessage,
  handleContinue,
}) => {
  const HIDE_EDIT = true;
  return (
    <Container>
      <Button onClick={handleRetryMessage}>
        <FaRedo />
      </Button>
      {!HIDE_EDIT && (
        <Button onClick={handleEditMessage}>
          <FaEdit />
        </Button>
      )}
      <Button onClick={handleContinue}>
        <FaPlay />
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 6px;
  margin-right: 0px;
  gap: 16px;
`;

const Button = styled.button`
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.05);
  padding-top: 5px;
  padding-left: 7px;
  border: none;
  border-radius: 50%;

  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.1);
  }
`;
export default ActionButtonsInMessage;
