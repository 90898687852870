import styled from 'styled-components';
import { BACKGROUND_COLOR, SECONDARY_TEXT_COLOR } from '../../../../constants';
import { desktopMediaQuery } from '../../../../styleHelpers';
import { useNavigate } from 'react-router-dom';
import JoiBotLogo from './JoiBotLogo';
import TopMenuContainer from './TopMenuContainer';

const TopBar = () => {
  const navigate = useNavigate();
  const handleTakeToLandingPage = () => {
    navigate('/');
  };
  return (
    <TopBarContainer>
      <LeftSideContainerInTopBar onClick={handleTakeToLandingPage}>
        <JoiBotLogo />
      </LeftSideContainerInTopBar>
      <RightSideContainerInTopBar>
        <TopMenuContainer />
      </RightSideContainerInTopBar>
    </TopBarContainer>
  );
};

export default TopBar;

const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${BACKGROUND_COLOR};
  color: ${SECONDARY_TEXT_COLOR};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.9);

  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;

  width: 92%;
  ${desktopMediaQuery} {
    padding-left: 64px;
    padding-right: 48px;
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

const LeftSideContainerInTopBar = styled.div`
  cursor: pointer;
`;

const RightSideContainerInTopBar = styled.div`
  display: flex;
  gap: 8px;
`;
