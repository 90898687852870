import styled, { keyframes } from 'styled-components';
import { fadeInWithBounce } from '../../common/animations';
import {
  USER_MESSAGE_COLOR,
  BOT_MESSAGE_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';

const TypingAnimation = () => {
  return (
    <MessageContainer>
      <MessageContent>
        <Dot />
        <Dot />
        <Dot />
      </MessageContent>
    </MessageContainer>
  );
};

export default TypingAnimation;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${PRIMARY_TEXT_COLOR};
  border-radius: 50%;
  margin: 0 2px;
  animation: ${bounce} 1s infinite;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  max-width: 82vw;

  ${desktopMediaQuery} {
    max-width: 30vw;
  }

  margin-bottom: 2px;

  position: relative;
`;

const MessageContent = styled.div<{ isUser?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: ${fadeInWithBounce} 0.3s ease-in-out;
  background-color: ${({ isUser }) =>
    isUser ? USER_MESSAGE_COLOR : BOT_MESSAGE_COLOR};
  border-radius: ${({ isUser }) =>
    isUser ? '16px 0px 16px 16px' : '0px 16px 16px 16px'};
  padding: 8px;
  color: ${PRIMARY_TEXT_COLOR};

  font-size: 16px;

  height: 20px;

  width: 60px;
  ${desktopMediaQuery} {
    font-size: 18px;
  }
`;
