import React, { createContext, useState, ReactNode, useContext } from 'react';
import { GenericSetState } from '../types';

interface ModalsContextType {
  showAppearanceModal: boolean;
  setShowAppearanceModal: GenericSetState<boolean>;
  showSettingsModal: boolean;
  setShowSettingsModal: GenericSetState<boolean>;
  showImagesModal: boolean;
  setShowImagesModal: GenericSetState<boolean>;

  showBuySubscriptionModal: boolean;
  setShowBuySubscriptionModal: GenericSetState<boolean>;
}

const defaultContext: ModalsContextType = {
  showAppearanceModal: false,
  setShowAppearanceModal: () => {},
  showSettingsModal: false,
  setShowSettingsModal: () => {},
  showImagesModal: false,
  setShowImagesModal: () => {},

  showBuySubscriptionModal: false,
  setShowBuySubscriptionModal: () => {},
};

const ModalsContext = createContext<ModalsContextType>(defaultContext);
const useModalsContext = () => useContext(ModalsContext);

const ModalsContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [showAppearanceModal, setShowAppearanceModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [showBuySubscriptionModal, setShowBuySubscriptionModal] = useState(false);

  return (
    <ModalsContext.Provider
      value={{
        showAppearanceModal,
        setShowAppearanceModal,
        showSettingsModal,
        setShowSettingsModal,
        showImagesModal,
        setShowImagesModal,

        showBuySubscriptionModal,
        setShowBuySubscriptionModal,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
};

export { ModalsContextProvider, useModalsContext };
