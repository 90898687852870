import { v4 as uuidv4 } from 'uuid';

export const headersForFetching = {
  'Content-type': 'Application/json',
  'Access-Control-Allow-Origin': '*',
};

export const scrollToTopSmoothly = async () => {
  await waitSeconds(0.7);
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const scrollToXSmoothly = (x: number) => {
  window.scrollTo({ top: x, behavior: 'smooth' });
};

export const waitSeconds = (seconds: number) =>
  new Promise((resolve) => setTimeout(resolve, seconds * 1000));

const MAX_SEED = 4294967295;

export const getRandomSeed = () => {
  return Math.floor(Math.random() * MAX_SEED);
};

/**
 * Generates a random track id using uuidv4
 */
export const generateTrackId = () => {
  const trackId = uuidv4();
  return trackId + "-aix";
};

export const getIsJoiBot = () => window.location.hostname === 'joi.bot';
