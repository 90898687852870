import { useState } from 'react';
import {
  FormContainer,
  IconAndInputContainer,
  SingleLineInputFieldInAuth,
} from './authSharedComponents';
import {
  RiEyeCloseLine,
  RiEyeLine,
  RiLockUnlockLine,
  RiUserLine,
} from 'react-icons/ri';
import styled from 'styled-components';
import StrongButton from '../../common/components/StrongButton';
import usePasswordVisibility from './usePasswordVisibility';
import useApi from '../../hooks/useApi';
import { toast } from 'react-toastify';
import UserTokenStorage from '../../common/storages/userTokenStorage';
import { FaSignInAlt } from 'react-icons/fa';
import { useAuthContext } from '../../context/AuthContextProvider';

interface I {
  closeModal: () => void;
}

const LoginContent = ({ closeModal }: I) => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { eyeIcon, handleClickOnEye } = usePasswordVisibility('password');

  const { apiCall, loading } = useApi('login', 'POST');

  const { setLoggedInUserToken } = useAuthContext();

  const handleLogin = async (username: string, password: string) => {
    if (loading) return;
    if (username.length < 4 || password.length < 4) {
      toast.error('Username and password must be at least 4 characters long');
      return;
    }
    const response = await apiCall({ username, password });
    const token = response?.data?.token;

    if (response.status === 401) {
      toast.error('User not found or invalid password');
      return;
    }

    if (response.status !== 200 || !token) {
      toast.error('Error logging in. Please try again.');
      return;
    }

    const userTokenStorage = new UserTokenStorage();
    userTokenStorage.setToken(token);
    setLoggedInUserToken(token);
    closeModal();
    toast.success('Logged in successfully');
  };

  const EYE_ICON_SIZE = 17;
  return (
    <div>
      <h2>Welcome back 👋</h2>
      <FormContainer>
        <IconAndInputContainer>
          <RiUserLine size={32} />
          <SingleLineInputFieldInAuth
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </IconAndInputContainer>

        <IconAndInputContainer
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleLogin(username, password);
            }
          }}
        >
          <RiLockUnlockLine size={32} />
          <SingleLineInputFieldInAuth
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            id="password"
          />
          <EyeIconContainer>
            {eyeIcon === 'closed' ? (
              <RiEyeCloseLine size={EYE_ICON_SIZE} onClick={handleClickOnEye} />
            ) : (
              <RiEyeLine size={EYE_ICON_SIZE} onClick={handleClickOnEye} />
            )}
          </EyeIconContainer>
        </IconAndInputContainer>
      </FormContainer>

      <StrongButton onClick={() => handleLogin(username, password)}>
        <FaSignInAlt /> Login
      </StrongButton>
    </div>
  );
};

const EyeIconContainer = styled.div`
  margin-top: 4px;
  margin-right: -23px;
  margin-left: -6px;
`;

export default LoginContent;
