import React, { createContext, useState, ReactNode, useContext } from 'react';
import { GenericSetState } from '../types';

interface AuthContextType {
  showAuthModal: boolean;
  setShowAuthModal: GenericSetState<boolean>;
  authModalActiveVariant: string;
  setAuthModalActiveVariant: GenericSetState<string>;

  loggedInUserToken: string;
  setLoggedInUserToken: GenericSetState<string>;
}

const defaultContext: AuthContextType = {
  showAuthModal: false,
  setShowAuthModal: () => {},
  authModalActiveVariant: 'login',
  setAuthModalActiveVariant: () => {},

  loggedInUserToken: '',
  setLoggedInUserToken: () => {},
};

const AuthContext = createContext<AuthContextType>(defaultContext);
const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authModalActiveVariant, setAuthModalActiveVariant] = useState('login');
  const [loggedInUserToken, setLoggedInUserToken] = useState('');

  return (
    <AuthContext.Provider
      value={{
        showAuthModal,
        setShowAuthModal,
        authModalActiveVariant,
        setAuthModalActiveVariant,

        loggedInUserToken,
        setLoggedInUserToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, useAuthContext };
