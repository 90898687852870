import styled from 'styled-components';
import {
  MAX_WIDTH,
  BORDER_COLOR,
  PRIMARY_COLOR,
  BACKGROUND_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';

export const IconAndInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  border-radius: 4px;
  border: 1.5px solid ${BORDER_COLOR};
  background-color: transparent;
  padding-left: 4px;

  ${desktopMediaQuery} {
    &:hover {
      border: 1.5px solid ${PRIMARY_COLOR};
    }
  }
`;

export const SingleLineInputFieldInAuth = styled.input`
  border: none;
  width: 100%;
  border-radius: 24px;
  padding: 14px;
  padding-bottom: 10px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  color: ${PRIMARY_TEXT_COLOR};
  background-color: transparent;
  word-break: break-word;
  &:focus {
    outline: none;
  }
  :-webkit-autofill {
    filter: none;
    box-shadow: 0 0 0 40px ${BACKGROUND_COLOR} inset;
    -webkit-text-fill-color: ${PRIMARY_TEXT_COLOR} !important;
  }
  resize: none;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
`;
